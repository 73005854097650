import { TransactionReceipt } from "ethers";
import { Receipt } from "./types";

export const COLORS = {
  red: "#F23927",
  grey: "#F4F4F4",
  white: "#FFFFFF",
  black: "#000000",
  yellow: "#FFF6C8",
};

export const FORMAT_DATA = "dd/MM/yyyy - kk:mm";

export const POLYGON_EXPLORER = "https://polygonscan.com";
export const ETHEREUM_EXPLORER = "https://etherscan.io";

export const TX_DATA: (keyof TransactionReceipt)[] = [
  "hash",
  "blockNumber",
  // "blockHash",
  "from",
  "to",
];

export const fillColors = {
  primary: "fill-primary",
  secondary: "fill-secondary",
  white: "fill-white",
  black: "fill-black",
  grey: "fill-grey",
  "light-grey": "fill-light-grey",
  accent: "fill-accent",
  "light-blue-100": "fill-light-blue-100",
  "light-blue-200": "fill-light-blue-200",
  "light-blue-300": "fill-light-blue-300",
  "light-blue-400": "fill-light-blue-400",
  "light-blue-500": "fill-light-blue-500",
  "dark-blue-100": "fill-dark-blue-100",
  "dark-blue-200": "fill-dark-blue-200",
  "dark-blue-300": "fill-dark-blue-300",
  "dark-blue-400": "fill-dark-blue-400",
  "dark-blue-500": "fill-dark-blue-500",
  "yellow-500": "fill-yellow-500",
  inherit: "fill-inherit",
  error: "fill-error",
  warning: "fill-warning",
  success: "fill-success",
  transparent: "fill-transparent",
};

export const textColors = {
  primary: "text-primary",
  secondary: "text-secondary",
  white: "text-white",
  black: "text-black",
  grey: "text-grey",
  "light-grey": "text-light-grey",
  accent: "text-accent",
  "light-blue-100": "text-light-blue-100",
  "light-blue-200": "text-light-blue-200",
  "light-blue-300": "text-light-blue-300",
  "light-blue-400": "text-light-blue-400",
  "light-blue-500": "text-light-blue-500",
  "dark-blue-100": "text-dark-blue-100",
  "dark-blue-200": "text-dark-blue-200",
  "dark-blue-300": "text-dark-blue-300",
  "dark-blue-400": "text-dark-blue-400",
  "dark-blue-500": "text-dark-blue-500",
  "yellow-500": "text-yellow-500",
  inherit: "text-inherit",
  error: "text-error",
  warning: "text-warning",
  success: "text-success",
  transparent: "text-transparent",
};
