//react
import React from "react";

//components
import { Button, Input, Typography } from "./components/index";
import { EthereumIcon, PolygonIcon, ArrowIcon } from "./assets/icons";

//style
import { useState } from "react";
import { useQuery } from "react-query";
import { provider } from "./imports/config";
import Lottie from "react-lottie-player";
import { Loader, Loading } from "./assets/animations/index";
import { getExplorerLink, truncStringPortion } from "./imports/utils";
import { useTranslation } from "react-i18next";
import { Notarization, Receipt } from "./imports/types";
import { ethers, TransactionReceipt } from "ethers";
import { TX_DATA } from "./imports/constants";
import axios from "axios";
import MerkleTree from "merkletreejs";

function App() {
  const { t } = useTranslation();

  const [tx, setTx] = useState<(Receipt & TransactionReceipt) | null>(null);
  const [activeQuery, setActiveQuery] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [error, setError] = useState(false);

  const { isLoading, isFetching } = useQuery(
    "searchUsers",
    async () => {
      try {
        setError(false);
        const transaction = await provider.getTransactionReceipt(searchField);

        if (transaction) {
          const block = await provider.getBlock(transaction?.blockNumber);
          const timestamp = (block?.timestamp || 0) * 1000;

          const abiCoder = ethers.AbiCoder.defaultAbiCoder();
          let notarizations = [];
          try {
            const uri = abiCoder.decode(
              ["bytes32", "string", "string"],
              transaction.logs[0].data
            )[1];

            notarizations = (
              await axios.get(
                uri.replace("https://ipfs.io", "https://bcode.mypinata.cloud")
              )
            ).data;
          } catch (e) {
            console.log(e);
          }

          // const tree = new MerkleTree(
          //   notarizations.map(({ hash }: { hash: string }) => hash)
          // );
          // const proof = tree.getProof(notarizations[0].hash);

          setTx({
            ...transaction,
            network: "polygon",
            notarizations,
            timestamp,
          } as Receipt & TransactionReceipt);
        }
      } catch (err) {
        setError(true);
        console.log("TRANSACTION NOT FOUND", err);
      }

      setActiveQuery(false);
    },
    { enabled: activeQuery }
  );

  console.log(error);

  return (
    <div className="">
      {/* <Topbar /> */}
      <div className="flex items-start justify-center w-full min-h-screen p-10">
        <div className="w-full">
          <div className="w-full">
            <Typography className="w-full text-left" weight="bold" size="xl">
              {t("search")}
            </Typography>
            <div className="flex items-center justify-between w-full gap-3 mt-5">
              <Input
                placeholder={t("labels.insert_txHash") as string}
                onChange={(e) => setSearchField(e.target.value)}
                className="w-full min-w-[450px]"
              />
              <Button
                type="primary"
                action={() => {
                  setTx(null);
                  setActiveQuery(true);
                }}
                className="max-w-[250px]"
              >
                {t("search")}
              </Button>
            </div>
          </div>
          {isLoading || isFetching ? (
            <div className="flex items-center justify-center w-full p-8 m-5">
              <Lottie className="w-36 h-36" play animationData={Loader} />
            </div>
          ) : tx ? (
            <div className="py-5">
              <Typography weight="bold">
                {t("home.transaction_data")}
              </Typography>

              <div className="w-full p-4 my-5 rounded-md bg-[#f3f3f3]">
                {TX_DATA.map((key) => (
                  <div key={key as string} className="flex w-full h-8">
                    <table className="w-full">
                      <thead>
                        <tr>
                          <td className="w-[220px]">
                            <Typography weight="bold">
                              {t(`labels.${key as string}`)}
                            </Typography>
                          </td>
                          <td>
                            <Typography>
                              {
                                tx[
                                  key as keyof (Receipt & TransactionReceipt)
                                ] as string
                              }
                            </Typography>
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                ))}
              </div>
              <Typography weight="bold">{t("home.bcode-box.title")}</Typography>
              <div className="w-full p-4 mt-5 rounded-md bg-[#f3f3f3]">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th>
                        <Typography weight="bold" className="text-left">
                          {t("labels.index")}
                        </Typography>
                      </th>
                      <th>
                        <Typography weight="bold" className="text-left">
                          {t("labels.notarization_hash")}
                        </Typography>
                      </th>
                      <th>
                        <Typography weight="bold" className="text-left">
                          {t("labels.address")}
                        </Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tx.notarizations.map(
                      ({ hash, address }: Notarization, index) => (
                        <tr key={hash} className="h-8">
                          <td>
                            <Typography>{`#${index + 1}`}</Typography>
                          </td>
                          <td>
                            <Typography>{hash}</Typography>
                          </td>
                          <td>
                            <Typography>{address}</Typography>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                {/* <div className="flex w-full gap-3">
                  <Typography weight="bold" className="">
                    {t("labels.index")}
                  </Typography>
                  <div className="grid grid-cols-2">
                    <Typography weight="bold">
                      {t("labels.notarization_hash")}
                    </Typography>
                    <Typography weight="bold">{t("labels.address")}</Typography>
                  </div>
                </div>
                {tx.notarizations.map(
                  ({ hash, address }: Notarization, index) => (
                    <div key={hash} className="flex w-full gap-3">
                      <Typography>{`#${index + 1}`}</Typography>
                      <div className="grid grid-cols-2">
                        <Typography>{hash}</Typography>
                        <Typography>{address}</Typography>
                      </div>
                    </div>
                  )
                )}*/}
              </div>

              {/* <Field
                label={t("labels.txHash") as string}
                // tooltipText={t("tooltips.txHash")}
                labelElementRight={
                  <div className="flex flex-row items-center justify-between gap-3 text-xl">
                    {tx.network === "ethereum" ? (
                      <EthereumIcon />
                    ) : (
                      <PolygonIcon />
                    )}
                    <a
                      className="text link"
                      href={getExplorerLink(tx.network)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t(tx.network) as string}
                    </a>
                  </div>
                }
              >
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href={`${getExplorerLink(tx.network)}/tx/${tx.hash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {truncStringPortion(tx?.hash || "", 25)}
                </a>
              </Field>
              <div className="flex justify-between">
                <Field
                  label={t("labels.blockHash") as string}

                  // tooltipText={t("tooltips.blockHash")}

                  // bottomRightItem={
                  //   <div className="item-container">
                  //     {format(parseInt(tx.timestamp), FORMAT_DATA)}
                  //   </div>
                  // }
                >
                  <a
                    style={{ textDecoration: "none", color: "black" }}
                    href={`${getExplorerLink(tx.network)}/blocks/${
                      tx.blockHash
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {truncStringPortion(tx.blockHash, 25)}
                  </a>
                </Field>
                <Typography
                  className="w-fit text-middle"
                  size="xl"
                  weight="semibold"
                >
                  {`${t("labels.block")} #${tx.blockNumber}`}
                </Typography>
              </div>
              <div className="flex flex-row items-center justify-between w-full gap-2">
                <Field label={t("labels.from") as string}>{tx.from}</Field>
                <ArrowIcon />
                <Field label={t("labels.to") as string}>{tx.to}</Field>
              </div> */}
            </div>
          ) : error ? (
            <Typography className="w-full p-8">
              {t("errors.problem_found")}
            </Typography>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
