import type { ReactNode } from 'react';
import React from 'react';

import type { InputType } from '../../imports/types';

import './input.css';

type InputProps = InputType & {
  minValue?: string;
  maxValue?: string;
  elementLeft?: ReactNode;
  elementRight?: ReactNode;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  onWheel?: React.WheelEventHandler<HTMLInputElement>;
  className?: string;
};

const Input = ({
  id,
  name,
  type,
  value,
  defaultValue,
  placeholder,
  disabled,
  error,
  success,
  onBlur,
  onChange,
  inputRef,
  minValue,
  maxValue,
  elementLeft,
  elementRight,
  className,
  onKeyUp,
  onWheel,
}: InputProps) => {
  const getInputStatus = () => {
    let status = '';

    if (error) {
      status = 'error';
    }
    if (success) {
      status = 'success';
    }
    if (disabled) {
      status = 'disabled';
    }

    return status;
  };

  const getInputPaddingX = () => {
    let padding = '';

    if (elementLeft) {
      padding = `${padding} with-element-left`;
    }
    if (elementRight) {
      padding = `${padding} with-element-right`;
    }

    return padding;
  };

  return (
    <div className="input-container">
      {elementLeft && <div className="input-element left">{elementLeft}</div>}
      {elementRight && <div className="input-element right">{elementRight}</div>}

      <input
        className={`input ${getInputPaddingX()} ${getInputStatus()} ${className}`}
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        min={minValue}
        max={maxValue}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        ref={inputRef}
        onKeyUp={onKeyUp}
        onWheel={onWheel}
      />
    </div>
  );
};

export default Input;
