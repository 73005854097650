import { forwardRef, FunctionComponent, ReactNode, SVGProps } from "react";
import type { Colors } from "../../imports/types";

import Icon from "../icon/Icon";
import Typography from "../typography/Typography";

import "./button.css";

type ButtonProps = {
  action: () => void;
  children?: ReactNode;
  className?: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  iconSize?: "sm" | "md" | "lg";
  iconColor?: Colors;
  type?: "primary" | "secondary" | "tertiary" | "ghost" | "ghost-dark";
  loading?: boolean;
  disabled?: boolean;
};

const Button = forwardRef((props: ButtonProps, ref: any) => {
  const {
    action,
    children,
    className = "",
    icon,
    iconSize = "md",
    iconColor,
    type = "primary",
    loading,
    disabled,
  } = props;

  const isIcon = !children && icon;
  const isDisabled = disabled || loading;

  return (
    <button
      type="button"
      className={`btn whitespace-nowrap px-[32px] ${type} ${
        isDisabled ? "disabled" : ""
      } ${isIcon ? "icon" : ""} ${className}`}
      onClick={action}
      disabled={isDisabled}
      ref={ref}
    >
      {isIcon ? (
        <Icon
          icon={icon}
          size={iconSize}
          color={iconColor}
          loading={loading}
          className={`${
            type === "ghost" && isDisabled
              ? "cursor-not-allowed opacity-50"
              : "opacity-100"
          }`}
        />
      ) : (
        <>
          {loading && <Icon size={iconSize} color={iconColor} loading />}
          {icon && !loading && (
            <Icon icon={icon} size={iconSize} color={iconColor} />
          )}

          <Typography weight="bold">{children}</Typography>
        </>
      )}
    </button>
  );
});

export default Button;
